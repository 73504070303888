import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";

const Trashed = () => {
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  useEffect(() => {
    axios
      .get(`https://altarefi.icrcompany.net/api/v2/competitions?trashed=1`)
      .then((re) => {
        setData(re.data.data.data);
        setPageCount(Math.ceil(re.data.data.total / re.data.data.per_page));
      })
      .catch((e) => "");
  }, []);
  const deleteData = (id) => {
    axios
      .delete(
        `https://altarefi.icrcompany.net/api/v2/competitions/${id}?delete=permanently`
      )
      .then((re) => {
        setData(data.filter((p) => p.id !== id));
      })
      .catch((e) => "");
  };
  const restoreData = (id) => {
    axios
      .post(`https://altarefi.icrcompany.net/api/v2/competitions/${id}/restore`)
      .then((re) => {
        setData(data.filter((p) => p.id !== id));
      })
      .catch((e) => "");
  };
  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-5">
      <div className="w-100 oo">
        <Table className=" text-center tt m-auto">
          <thead>
            <tr>
              <th>Competitions</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((e) => (
              <tr>
                <td>{e.name}</td>
                <td className="d-flex justify-content-center gap-1">
                  <ReData clickRe={() => restoreData(e.id)} />
                  <Delete clickDelete={() => deleteData(e.id)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <ReactPaginate
        className="pagination"
        previousLabel=""
        nextLabel=""
        breakLabel="..."
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={""}
        pageClassName="page-item"
        pageLinkClassName="page-link"
      />
    </div>
  );
};

export default Trashed;
const Delete = (props) => {
  const [deletex, setDeletex] = useState(false);
  return (
    <>
      <div
        className={`messge  ${deletex ? "" : "d-none"}`}
        onClick={() => setDeletex(false)}
      >
        <div>
          <h5>Are you sure to delete this item?</h5>
          <div>
            <button onClick={props.clickDelete}>Yes</button>
            <button onClick={() => setDeletex(false)}>No</button>
          </div>
        </div>
      </div>
      <div
        onClick={() => setDeletex(true)}
        className="px-2 py-1 border rounded "
        style={{ cursor: "pointer", color: "#ce2e91" }}
      >
        Delete
      </div>
    </>
  );
};
const ReData = (props) => {
  const [deletex, setDeletex] = useState(false);
  return (
    <>
      <div
        className={`messge  ${deletex ? "" : "d-none"}`}
        onClick={() => setDeletex(false)}
      >
        <div>
          <h5>Are you sure to restore this item?</h5>
          <div>
            <button onClick={props.clickRe}>Yes</button>
            <button onClick={() => setDeletex(false)}>No</button>
          </div>
        </div>
      </div>
      <div
        onClick={() => setDeletex(true)}
        className="px-2 py-1 border rounded "
        style={{ cursor: "pointer", color: "#762a8d" }}
      >
        Restore
      </div>
    </>
  );
};
